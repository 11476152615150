import React, { useState } from "react"
import { useMediaQuery, Box, Grid } from "@material-ui/core"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "./styles/swipe-lightbox.css"

const SwipeLightBox = ({ images }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const xs = useMediaQuery("(max-width:500px)")
  const sm = useMediaQuery("(max-width:700px)")
  const md = useMediaQuery("(max-width:1000px)")
  const lg = useMediaQuery("(max-width:1200px)")

  let displayItem

  if (xs) {
    displayItem = images.length < 1 ? images.length : 1
  } else if (sm) {
    displayItem = images.length < 2 ? images.length : 2
  } else if (md) {
    displayItem = images.length < 3 ? images.length : 3
  } else if (lg) {
    displayItem = images.length < 4 ? images.length : 4
  } else {
    displayItem = images.length < 5 ? images.length : 5
  }

  function onImageClick(index) {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <Grid container>
      <Grid item>
        <Box>
          {images.map((item, index) => {
            return (
              <Box key={index} width={1 / displayItem} display="inline-block">
                <div
                  onClick={() => onImageClick(index)}
                  style={{
                    padding: "10px",
                  }}
                >
                  <img
                    className="hvr-grow-shadow"
                    src={item}
                    alt={index}
                    style={{ width: "100%" }}
                  />
                </div>
              </Box>
            )
          })}
        </Box>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </Grid>
    </Grid>
  )
}

export default SwipeLightBox
