import React, { Component } from "react"
import { navigate } from "@reach/router"
import { inject, observer } from "mobx-react"
import { Grid, Button } from "@material-ui/core"
import renderHTML from "react-render-html"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import withLocation from "components/withLocation.js"
import NumberFormat from "react-number-format"
import SwipeLightBox from "../../components/swipeLightBox"
import { Link } from "gatsby"
import "./styles/house.css"

class House extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  componentDidMount = async () => {
    const { id } = this.props.search
    if (id) {
      await this.props.house.fetchHouse(id)
    } else {
      navigate(`/404`)
    }
  }

  renderHouseHighlight = houseIcons => {
    return houseIcons
      .filter(f => f.type === "highlight")
      .map((item, index) => {
        if (index === 3) {
          return true
        }
        return (
          <Grid item key={index} xs={4} md={4} align="center">
            <div className="h-hvr-float-shadow">
              <img
                src={
                  item.icon.url ||
                  `${process.env.AWS_S3_URL}/assets/img-null.png`
                }
                style={{
                  width: "60%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  paddingBottom: "15px",
                }}
                alt="icon-highlight"
              />
            </div>

            <span className="h-house-icon-desc">{item.description || ""}</span>
          </Grid>
        )
      })
  }

  renderHouseIcon = (houseIcons, type) => {
    return houseIcons
      .filter(f => f.type === type)
      .map((item, index) => {
        return (
          <Grid item key={index} xs={6} md={3} align="center">
            <img
              src={
                item.icon.url ||
                `${process.env.AWS_S3_URL}/assets/img-null.png`
              }
              style={{ width: "80px" }}
              alt=""
            />
            <br />
            <br />
            <h5>{item.description || ""}</h5>
          </Grid>
        )
      })
  }

  render() {
    const house = this.props.house.getHouseJS()
    let houseImageHighLight = house.house_images
      ? house.house_images.find(f => f.is_highlight)
      : null
    if (!houseImageHighLight) {
      houseImageHighLight = house.house_images[0] || {
        url:
        `${process.env.AWS_S3_URL}/assets/img-null.png`,
      }
    }

    const houseImages = house.house_images
      ? house.house_images.map(item => item.url)
      : []
    const houseIcons = house.house_icons ? house.house_icons : []
    const { id } = this.props.search
    const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`;
    return (
      <React.Fragment>
        <Layout page="house">
          {/* <SEO
            title={house.name || ""}
            description={house.short_detail || ""}
            image={houseImageHighLight.url}
          /> */}
          <SEO
            title="BEST HAVEN POOLVILLA"
            description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
            image={imgShare}
          />
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container spacing={2}>
                {/* รูป Highlight */}
                <Grid item xs={12} md={6} align="center">
                  <img
                    src={
                      houseImageHighLight
                        ? houseImageHighLight.url
                        : `${process.env.AWS_S3_URL}/assets/img-null.png`
                    }
                    alt="img_highlight"
                    className="h-hvr-grow-shadow"
                  />
                </Grid>
                {/* รายละเอียดแบบสั้น */}
                <Grid item xs={12} md={6} align="center">
                  <br />
                  <span className="h-header">{house.name || ""}</span>
                  <div className="h-header-line"></div>
                  <br />
                  <span>{renderHTML(house.short_detail || "")}</span>

                  <Grid container spacing={1} align="center">
                    {this.renderHouseHighlight(houseIcons)}
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {house.price_detail.price >
                        house.price_detail.special_price && (
                        <div>
                          <span className="h-price">จากราคาปกติ: </span>
                          <span className="h-price-normal">
                            <NumberFormat
                              value={house.price_detail.price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"THB "}
                            />
                          </span>
                          <br />
                          &nbsp;&nbsp;&nbsp;
                          <span className="h-price">ราคาพิเศษ: </span>
                          <span className="h-price-special">
                            <NumberFormat
                              value={house.price_detail.special_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"THB "}
                            />
                          </span>
                        </div>
                      )}
                      {house.price_detail.price <=
                        house.price_detail.special_price && (
                        <div>
                          <span className="h-price">ราคา: </span>
                          <span className="h-price-special">
                            <NumberFormat
                              value={house.price_detail.special_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"THB "}
                            />
                          </span>
                        </div>
                      )}
                      {house.insurance_price > 0 && (
                        <div>
                          <span className="h-price-insurance">
                            {" "}
                            ( เงินประกัน :{" "}
                          </span>
                          <span className="h-price-insurance">
                            <NumberFormat
                              value={house.insurance_price || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"THB "}
                            />
                          </span>
                          <span className="h-price-insurance">
                            {" "}
                            จ่ายวันเข้าพัก ){" "}
                          </span>
                        </div>
                      )}
                      <br />
                      <br />
                      <Link
                        to={`/Booking?id=${id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button variant="contained" color="secondary" fullWidth>
                          จองเลย!!
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={1}>
                <Grid item md={12}>
                  <header className="h-sub-header">รายละเอียดบ้านพัก</header>
                  <Grid container spacing={1}>
                    {this.renderHouseIcon(houseIcons, "detail")}
                  </Grid>
                </Grid>
              </Grid>

              <br />
              <br />

              <Grid container spacing={1}>
                <Grid item md={12}>
                  <header className="h-sub-header">สิ่งอำนวยความสะดวก</header>
                  <Grid container spacing={1}>
                    {this.renderHouseIcon(houseIcons, "facility")}
                  </Grid>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={1}>
                {house.nearly_restaurant !== "" && 
                 <Grid item xs={12} sm={6} md={3}>
                  <header className="h-sub-header">
                    ร้านอาหารและคาเฟ่ใกล้เคียง
                  </header>
                  <div className="h-content">
                    {renderHTML(house.nearly_restaurant || "")}
                  </div>
                </Grid>
                }
                <Grid item xs={12} sm={6} md={house.nearly_restaurant !== "" ? 3 : 4}>
                  <header className="h-sub-header">
                    สถานที่และแหล่งท่องเที่ยวใกล้เคียง
                  </header>
                  <div className="h-content">
                    {renderHTML(house.nearly_landmark || "")}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={house.nearly_restaurant !== "" ? 3 : 4}>
                  <header className="h-sub-header">กฎระเบียบบ้านพัก</header>
                  <div className="h-content">
                    {renderHTML(house.term_and_condition || "")}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={house.nearly_restaurant !== "" ? 3 : 4}>
                  <header className="h-sub-header">รายละเอียดเพิ่มเติม</header>
                  <div className="h-content">
                    {renderHTML(house.long_detail || "")}
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <b>- Check In : {house.check_in || ""}</b>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <b>- Check Out : {house.check_out || ""}</b>
                </Grid>
              </Grid>

              <br />

              <Grid container>
                <Grid item xs={12} md={12}>
                  <Link
                    to={`/Booking?id=${id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained" color="secondary" fullWidth>
                      จองเลย!!
                    </Button>
                  </Link>
                </Grid>
              </Grid>

              <br />
              <hr />

              {/* รูปบ้านอื่น ๆ */}
              <SwipeLightBox images={houseImages} />

              <br />
              <hr />
            </Grid>
          </Grid>
        </Layout>
      </React.Fragment>
    )
  }
}

export default inject("house")(withLocation(observer(House)))
